import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons';
import qs from 'qs';
import axios from 'src/contexts/AxiosInstance';
import { useStateContext } from 'src/contexts/ContextProvider';
import { alertMessage, strCrypt } from 'src/assets/js/functions';
import { routesName as route } from 'src/constants/constants';

const ConfirmationAccount = () => {
    const {user, token} = useParams();
	const [formData, setFormData] = useState({
		user: user,
		token: token,
		matricule: '',
		password: '',
		copy_password: '',
	});

    useEffect(() => {
        const url = baseURL + '/employe.getInfosForConfirmation'
        // console.log(user, token);
        const data = {
            user, token
        }
        axios.post(url, data).then(res => {
            res = res.data;
            if(res == false) {
                alertMessage(res.message, 'danger');
            }
        })
    }, []);

	const { baseURL } = useStateContext();
	const sendData = baseURL + '/employe.confirm_account';

  // Changement des values des inputs
  const handleChange = (e) => {
    const value = e.target.value;
    setFormData({
      ...formData,
      [e.target.name]: value
    });
  }

	// Soummission du formulaire de connexion
	const handleSubmit = (e) => {
		e.preventDefault();
		
		axios.post(sendData, formData).then(res => {
			res = res.data;
			if(res.validate == true) {
                window.location.href = route('login.index');
			} else {
                if(typeof res.message == 'string') {
                    alertMessage(res.message, 'danger');
                } else {
                    let errors = res.message;
                    let li = new Array();
                    for (const key in errors) {
                        if (Object.hasOwnProperty.call(errors, key)) {
                            const listErrors = errors[key];
                            if(listErrors) {
                                listErrors.forEach((error, index) => {
                                    // modification de 'index' pour ne pas avoir les mêmes valeurs
                                    index = ((index + 1) * Math.random());
                                    li.push(<li key={index}>{error}</li>);
                                });
                            }
                        }
                    }
                    
                    alertMessage(<ul style={{ marginLeft: '15px', marginBottom: 0, paddingLeft: 0 }}>{li}</ul>, 'danger');

                }
			}
		});
	}
  
  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol lg={8} xs={12}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm method='post'>
                    <h1>Confirmer votre compte</h1>
                    <p className="text-medium-emphasis">Confirmer votre compte</p>
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput placeholder="Matricule" name="matricule" autoComplete="matricule" value={formData.matricule} onChange={handleChange} />
                    </CInputGroup>

                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type="password"
                        name="password"
                        placeholder="Mot de passe"
                        autoComplete="current-password"
                        value={formData.password}
                        onChange={handleChange}
                      />
                    </CInputGroup>

                    <CInputGroup className="mb-4">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type="password"
                        name="copy_password"
                        placeholder="Retaper le mot de passe"
                        autoComplete="current-copy_password"
                        value={formData.copy_password}
                        onChange={handleChange}
                      />
                    </CInputGroup>
                    <CRow>
                      <CCol xs={7}>
                        <button className='btn btn-primary px-4' onClick={handleSubmit}>
                          Valider
                        </button>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default ConfirmationAccount
