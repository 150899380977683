import React, {useState, useContext, createContext, useEffect} from 'react';
import axiosInstance from './AxiosInstance';
import { getMsgFlash, isLoggedIn, linkAllowedToAccess, loading, logout } from 'src/assets/js/functions';
import { ToastContainer } from 'react-toastify';
import { routesName as route, baseURL } from 'src/constants/constants';
import bcrypt from 'bcryptjs';
import '../assets/css/fontawesome-5.15.4/css/all.min.css';
import { $ } from 'react-jquery-plugin';
import { useNavigate, useParams } from 'react-router-dom';

const StateContext = createContext();

export const ContextProvider = ({ children }) => {
    // console.log(useParams());
    const [userConnected, setUserConnected] = useState([]);
    const [optionForDeletingItem, setOptionForDeletingItem] = useState(null);
    const [dataList, setDataList] = useState([]);

    /* {
        selectedStore: null,
        listStores: [],
        defaultSelectedStore: null,
        sidebarNavigation: [],
    } */

    const [selectedStore, setSelectedStore] = useState(null);
    const [listStores, setListStores] = useState([]);
    const [defaultSelectedStore, setDefaultSelectedStore] = useState(null);
    const [sidebarNavigation, setSidebarNavigation] = useState([]);

    const [stateInContext, setStateInContext] = useState([]);

    const updateStateInContext = (newState) => {
        setStateInContext((prevState) => ({
            ...prevState, 
            ...newState 
        }));
      };
    
    /* useEffect(() => {
        console.log('stateContext', stateInContext);
    }, [stateInContext]); */
    
    useEffect(() => {
        if(stateInContext.defaultSelectedStore != null) {
            setStateInContext({
                ...stateInContext,
                ['selectedStore']: stateInContext.defaultSelectedStore.value,
              });
            // setSelectedStore(stateInContext.defaultSelectedStore.value);
        }
    }, [stateInContext.defaultSelectedStore]);

    useEffect(() => {
        // Récupération des messages flash
        getMsgFlash();
    
        // on vérifie d'abord s'il est connecté ou pas
        if(isLoggedIn()) {
            const sendData = baseURL + '/manager.login';
            let isConnected = false;
            const token = localStorage.getItem('token');
    
            axiosInstance.post(sendData).then(res => {
                res = res.data;
                if(res.validate == true) {
                    let user = res.message.userConnected;
    
                    isConnected = bcrypt.compareSync((user.id + user.user + user.email), token);
    
                    if(isConnected) {
                        setUserConnected(res.message.userConnected);
                        
                        // Mise à jour du logo et nom de l'application
                        updateStateInContext({ logo: res.message.app.logo });
                        updateStateInContext({ app_name: res.message.app.nom_app });
                        
                        if(window.location.pathname === '/login') {
                            window.location.href = route('home.index');
                        }
                    } else {
                        logout(); /* Déconnexion */
                    }
                } else {
                    logout();
                }
            }).catch(error => {
                logout();
            });
        } else {
            const url = window.location.pathname;
            const regex1 = /\/login\/confirmation\/[a-z0-9]+\/[a-z0-9]+/;
            const regex2 = /\/resetPassword\/[a-z0-9]+/;

            if (regex1.test(url)) {
                return;
            } else if(regex2.test(url)) {
                loading(false);
                // alert(regex2);
                return;
            }

            // Déconnexion
            logout();
        }
    }, []);
      
    useEffect(() => {
        if(Object.keys(userConnected).length > 0) {
            if(typeof userConnected.acces == 'string') {
                let access = JSON.parse(userConnected.acces);
                
                // window.history.pushState({}, '', 'nouvelle-url');
                // console.log(linkAllowedToAccess(access));
                
                let authorizedLinkToAccess = linkAllowedToAccess(access);
                
                setUserConnected({
                    ...userConnected,
                    ['acces']: access,
                    ['access']: access,
                    ['authorizedLinkToAccess']: authorizedLinkToAccess,
                    ['hasAccess']: (module) => {
                        if(authorizedLinkToAccess[module]) {
                            return true;
                        }
                        return false
                    }
                });
            }
        }
    }, [userConnected]);

    /* useEffect(() => {
		console.log(dataList);
	}, [dataList]); */

    const styles = {
        loader: {
            display: 'none',
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'rgba(0, 0, 0, 0.6)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999,
        },

        loaderContent: {
            // background: '#fff',
            padding: '20px',
            borderRadius: '5px',
            textAlign: 'center',
        }
    }
    
    if(userConnected) {
        return (
            userConnected && <StateContext.Provider value={{ 
                baseURL, 
                userConnected, 
                optionForDeletingItem, setOptionForDeletingItem,
                dataList, setDataList,
                axiosInstance,
                stateInContext, updateStateInContext,
                
                selectedStore, setSelectedStore,
                listStores, setListStores,
                sidebarNavigation, setSidebarNavigation,
                defaultSelectedStore, setDefaultSelectedStore
            }}>
                { children }
                <div className='axiosLoading' style={styles.loader}>
                    <div className='axioLoader' style={styles.loaderContent}>
                        <i className='fa fa-circle-notch bibi-arrow-clockwise fa-spin text-warning' style={{ fontSize: '70px' }}></i>
                    </div>
                </div>
                <ToastContainer />
            </StateContext.Provider>
        )
    } else {
        return (
            <StateContext.Provider value={{ 
                baseURL, 
                optionForDeletingItem, setOptionForDeletingItem,
                dataList, setDataList,
            }}>
                { children }
                <div className='axiosLoading' style={styles.loader}>
                    <div className='axioLoader' style={styles.loaderContent}>
                        <i className='fa fa-circle-notch bibi-arrow-clockwise fa-spin text-warning' style={{ fontSize: '70px' }}></i>
                    </div>
                </div>
                <ToastContainer />
            </StateContext.Provider>
        )
    }
}

export const useStateContext = () => useContext(StateContext);

