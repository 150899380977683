import axios from 'axios';
import { alertMessage, isLoggedIn, loading, redirectWithAlertMessage, strCrypt } from 'src/assets/js/functions';
import { baseURL, routesName as route } from 'src/constants/constants';

const instance = axios.create({
  baseURL: baseURL,  // Remplacez par votre URL de base
  // timeout: 5000,  // Configurer le timeout si nécessaire
});

const username = strCrypt(`${process.env.REACT_APP_AUTH_HEADER_USERNAME}`);
const password = strCrypt(`${process.env.REACT_APP_AUTH_HEADER_PASSWORD}`);
const token = (`${username}:${password}`);
// const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');

// Configuration générale pour toutes les requêtes
instance.defaults.withCredentials = true;
// instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
instance.defaults.headers.common['Authorization'] = `Basic ${btoa(token)}`;
// instance.defaults.headers.common['X-Custom-Header'] = 'AUTH_TOKEN';

// Ajouter un intercepteur pour les requêtes
instance.interceptors.request.use(config => {
  loading();
  let csrf = strCrypt('MyStore-2.0-weldeen-'+process.env.REACT_APP_AUTH_HEADER_PASSWORD);
  
  if(isLoggedIn()) {
    /* let store = localStorage.getItem('storeSelected');
    if(store == null) {
      store = 'all';
      localStorage.setItem('storeSelected', store);
    }
    config.data = { ...config.data, selectedStore: store }; */
  }

  // config.data += "&csrf="+csrf;
  if(config.data != undefined) {
    /* config.data = {
      ...config.data,
      csrf: csrf
    }; */
  }
  return config;
}, error => {
    loading(false);
    return Promise.reject(error);
});

// Intercepteur pour les réponses
instance.interceptors.response.use(
    response => {
      loading(false);
      
      let res = response.data;
      if(res.validate == false) {
        if(res.access == false) {
          redirectWithAlertMessage(res.message, 'danger', route('home.index'));
        }
      }

      return response;

      /* if(res.validate) {
        return response;
      } else {
        
        return response;
      } */
    },
    error => {
        loading(false);
        alertMessage("Connexion impossible avec le serveur", 'danger');
        return Promise.reject(error);
    }
);

export default instance;