import React, { Component, Suspense, useContext } from 'react'
import { BrowserRouter,HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import 'bootstrap-icons/font/bootstrap-icons.css';
import ConfirmationAccount from './views/pages/ConfirmationAccount';
import ResetPassword from './views/pages/ResetPassword';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))


const App = () => {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Suspense fallback={loading}>
        <Routes>
          <Route exact path="/login" name="Login Page" element={<Login />} />
          <Route exact path="/login/confirmation/:user/:token" name="Confirmation de compte" element={<ConfirmationAccount />} />
          <Route exact path="/resetPassword/:token" name="Réinitialiser le mot de passe" element={<ResetPassword />} />
          {/* <Route exact path="/register" name="Register Page" element={<Register />} /> */}
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          <Route path="*" name="Accueil" element={<DefaultLayout />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}

export default App
