import React from 'react';
import select2 from 'select2';
// import { $ } from 'react-jquery-plugin';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, redirect } from 'react-router-dom';
import bcrypt from 'bcryptjs';
import axios from 'src/contexts/AxiosInstance';
import { OPTIONS_SETTING, OPTIONS_SETTINGS_APPOINTMENTS, baseURL, routesLists } from 'src/constants/constants';
import { routesName as route } from 'src/constants/constants';
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.bootstrap5.min";
import "datatables.net-dt/css/dataTables.bootstrap5.min.css";
import $ from 'jquery';

export const select = () => {
    $('select').each(function () {

        let classLists = $(this).attr('class').split(' ');
        let i = 0;
        classLists.map(className => {
            if(i <= 2) {
                switch (className) {
                    case 'single-select':
                        $(this).select2({
                            theme: 'bootstrap4',
                            placeholder: $(this).data('placeholder'),
                            allowClear: Boolean($(this).data('allow-clear')),
                            closeOnSelect: !$(this).attr('multiple'),
                            width: $(this).data('width') ? $(this).data('width') : $(this).hasClass('w-100') ? '100%' : '100%',
                        });

                        i++;
                    break;
                    
                    case 'hide-searhBox': 
                        $(this).select2({
                            theme: 'bootstrap4',
                            minimumResultsForSearch: -1,
                        });
                        
                        i++;
                    break;
                
                    default:
                        break;
                }
            } else {
                alert(i);
                return false;
            }
        });
    });
}

export const optionListObject = (lists, selected = null, tag) => {
    let option = '<option value="none">Choisir...</option>';
    
    for (const key in lists) {
        if (Object.hasOwnProperty.call(lists, key)) {
            const element = lists[key];
            option += `<option value="${key}" ${(selected == key ? ' selected' : '')}>${element}</option>`;
        }
    }

    $(tag).html(option);
}

export const getList = (list, type = null, element) => {
    let option = '<option value="none">Choisir...</option>';

    list.forEach(value => {
        option += '<option value="'+value.value+'"'+ (type == value.value ? 'selected' : '') +'>'+value.name+'</option> ';
    });

    $(element).html(option);
}

export const getOptionList = (lists, type = null, element) => {
    let option = '<option value="none">Choisir...</option>';

    if(Array.isArray(lists)) {
        lists.forEach(object => {
            for (const key in object) {
                if (Object.hasOwnProperty.call(object, key)) {
                    const element = object[key];
                    option += ' <option value="'+key+'"'+ (type == key ? 'selected' : '') +'>'+element+'</option> ';
                }
            }
        });
    } else {
        for (const key in lists) {
            if (Object.hasOwnProperty.call(lists, key)) {
                const element = lists[key];
                option += ' <option value="'+key+'"'+ (type == key ? 'selected' : '') +'>'+element+'</option> ';
            }
        }
    }
    
    $(element).html(option);
    // return option;
}

export const hydrateSelectList = (lists, type = null, element) => {
    let option = '<option value="none">Choisir...</option>';

    if(Array.isArray(lists)) {
        lists.forEach(object => {
            option += '<option value="'+object.id+'"'+ (type == object.id ? 'selected' : '') +'>'+object.nom+'</option> ';
        });
    } else {
        return;
        for (const key in lists) {
            if (Object.hasOwnProperty.call(lists, key)) {
                const element = lists[key];
                option += '<option value="'+key+'"'+ (type == key ? 'selected' : '') +'>'+element+'</option> ';
            }
        }
    }
    
    $(element).html(option);
}

export const hydrateSelectListForCheckout = (lists, type = null, element) => {
    let option = '<option value="none">Choisir...</option>';
    option += '<option value="add">Ajouter</option>';

    if(Array.isArray(lists)) {
        lists.forEach(object => {
            option += '<option value="'+object.id+'"'+ (type == object.id ? 'selected' : '') +'>'+object.nom + ` (${object.telephone})` +'</option> ';
        });
    } else {
        for (const key in lists) {
            if (Object.hasOwnProperty.call(lists, key)) {
                const element = lists[key];
                option += '<option value="'+element.id+'"'+ (type == element.id ? 'selected' : '') +'>'+element.nom + ` (${element.telephone})` +'</option> ';
            }
        }
    }
    
    $(element).html(option);
}

export const optionsListToSelect = (lists) => {
    let array = [
        { value: 'none', label: 'Choisir' },
    ];

    if(Array.isArray(lists)) {
        lists.forEach(object => {
            array.push({
                value: object.id, label: object.nom
            });
        });
    } else {
        for (const key in lists) {
            if (Object.hasOwnProperty.call(lists, key)) {
                const element = lists[key];
                array.push({
                    value: key, label: element
                });
            }
        }
    }
    

    return array;
}

export const optionsListForTypeOfEntry = (lists) => {
    let array = [
        { value: 'none', label: 'Choisir' },
    ];
    
    for (const key in lists) {
        if (Object.hasOwnProperty.call(lists, key)) {
            const element = lists[key];
            array.push({
                value: key, label: element
            });
        }
    }

    return array;
}

export const setSelectLists = (lists) => {
    let array = [
        { value: 'none', label: 'Choisir' },
    ];

    if(Array.isArray(lists)) {
        lists.forEach(object => {
            array.push({
                value: object.value, label: object.name
            });
        });
    } else {
        for (const key in lists) {
            if (Object.hasOwnProperty.call(lists, key)) {
                const element = lists[key];
                array.push({
                    value: key, label: element
                });
            }
        }
    }

    return array;
}

export const setOptionsCategories = (lists) => {
    let array = [
        { value: 'none', label: 'Choisir' },
    ];

    // console.log(lists[0]);
    // return;

    if(Array.isArray(lists)) {
        lists.forEach(object => {
            array.push({
                value: object.value, label: object.name
            });
        });
    } else {
        return;
        for (const key in lists) {
            if (Object.hasOwnProperty.call(lists, key)) {
                const element = lists[key];
                console.log('eleemnt', element, key);
                /* array.push({
                    value: element.id, label: element.nom + ` (${element.telephone})`
                }); */
            }
        }
    }

    return array;
}

export const retrieveDataInfos = (data, value) => {
    for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
            const element = data[key];
            if(element.value == value) {
                return element;
            }
        }
    }

    return false;
}

export const getValueSelectedForOptionsList = (lists, value) => {
    for (const key in lists) {
        if (Object.hasOwnProperty.call(lists, key)) {
            const element = lists[key];
            if(element.value == value) {
                return element;
            }
        }
    }

    return [];
}

export const elementExistInTheOptionList = (lists, value) => {
    return getValueSelectedForOptionsList(lists, value);
}

export const selectListsForCustomer = (lists, withAdd = true) => {
    let array = [
        { value: 'none', label: 'Choisir' },
    ];

    if(withAdd) {
        array.push({ value: 'add', label: 'Ajouter' });
    }

    if(Array.isArray(lists)) {
        lists.forEach(object => {
            array.push({
                value: object.id, label: object.nom + ` (${object.telephone})`
            });
        });
    } else {
        for (const key in lists) {
            if (Object.hasOwnProperty.call(lists, key)) {
                const element = lists[key];
                array.push({
                    value: element.id, label: element.nom + ` (${element.telephone})`
                });
            }
        }
    }

    return array;
}

export const selectListsForReseller = (lists) => {
    return selectListsForCustomer(lists);
}

export const selectListsForEmployee = (lists) => {
    return selectListsForCustomer(lists, false);
}

export const inputController = (e, type = null) => {
    if(type == 'number') {
        if (e.keyCode === 8 || e.keyCode === 46) {
            return true;
        } else if ((e.keyCode >= 48 && e.keyCode <= 57)) {
            return true;
        } else {
            return false;
        }
    }
}

export const formatNumber = (number, decimals = 3, thousandsSeparator = ' ', decimalSeparator = '.') => {
    // Vérifier si 'number' est une chaîne qui peut être convertie en nombre
    if (!isNaN(Number(number))) {
        number = Number(number);  // Convertir en nombre
    } else {
        return null;
    }

    // Formater le nombre avec le bon nombre de décimales
    let formattedNumber = number.toFixed(decimals);

    // Supprimer les décimales si elles sont égales à zéro
    formattedNumber = formattedNumber.replace(/\.?0+$/, '');

    const parts = formattedNumber.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator);

    return parts.join(decimalSeparator);
}

export const formatNumber_EN = (nombre) => {
    if (nombre < 1000) {
      return nombre; // Pas besoin de formater si c'est moins de 1000
    } else if (nombre < 1000000) {
      return (nombre / 1000).toFixed(1) + 'k'; // Format pour les milliers
    } else if (nombre < 1000000000) {
      return (nombre / 1000000).toFixed(1) + 'M'; // Format pour les millions
    } else {
      return (nombre / 1000000000).toFixed(1) + 'B'; // Format pour les milliards
    }
  }
  

export const number_format = (number, decimals = 3, decPoint = '.', thousandsSep = ' ') => {
    if (Number.isInteger(number)) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSep);
    }

    number = parseFloat(number);
    if (isNaN(number) || !isFinite(number)) {
        throw new TypeError('number is not valid');
    }

    decimals = !isFinite(+decimals) ? 0 : Math.abs(decimals);
    const sign = number < 0 ? '-' : '';
    const integerPart = parseInt(number = Math.abs(+number || 0).toFixed(decimals), 10) + '';
    const thousandsGroup = (thousandsSep === undefined) ? ',' : thousandsSep;

    let output = integerPart.length > 3 ? integerPart.length % 3 : 0;

    return sign +
        (output ? integerPart.substr(0, output) + thousandsGroup : '') +
        integerPart.substr(output).replace(/(\d{3})(?=\d)/g, '$1' + thousandsGroup) +
        (decimals ? decPoint + Math.abs(number - integerPart).toFixed(decimals).slice(2) : '');
}


export const numberFormat = (n, d/*  = 'GNF' */) => {
	if(d && d.length > 0) {
		let f = new Intl.NumberFormat('fr-FR', {
		  style: 'currency',
		  currency: d,
		  minimumFractionDigits: 0
		});

		return f.format(n);
	} else {
		return new Intl.NumberFormat().format(n);
	}
}

export const getMsgFlash = () => {
    var msgFlash = localStorage.getItem('flash');
    if(msgFlash) {
        msgFlash = JSON.parse(msgFlash);
        alertMessage(msgFlash.message, msgFlash.type);
        localStorage.removeItem('flash');
    }
}

export const redirectWithAlertMessage = (message, type, to) => {
    // const navigate = useNavigate();
    const msg = {message: message, type: type};
    localStorage.setItem('flash', JSON.stringify(msg));

    // window.location.replace = '//localhost/' + to;
    // window.location.href = '//localhost:3000' + to;
    window.location.href = to;
    // window.location.reload();
    // window.history.back();

    // navigate(to);
    console.log(to);
    return redirect(to);
}

export const alertMessage = (message, type, options = {}) => {
    let defaultOptions = {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    }

    const newOptions = Object.assign(defaultOptions, options);

    switch (type) {
        case 'success':
            toast.success(message, newOptions);
            break;
        
        case 'info':
            toast.info(message, newOptions);
            break;
        
        case 'danger':
            toast.error(message, newOptions);
            break;
        
        case 'warning':
            toast.warn(message, newOptions);
            break;
    
        default:
        case 'warning':
            toast(message, newOptions);
            break;
    }
}

export const strCrypt = (text, salt = 10) => {
    return bcrypt.hashSync(text, salt);
}

export const crypt = (text, salt = 10) => {
    return strCrypt(text, salt);
}

export const isAuthorizedToAccess = (path, userConnected) => {
    // console.log(routesLists);
    for (const key in routesLists) {
        if (Object.hasOwnProperty.call(routesLists, key)) {
            const moduleItem = routesLists[key];

            // on parcours les sous menus
            for (const subKey in moduleItem) {
                if (Object.hasOwnProperty.call(moduleItem, subKey)) {
                    const element = moduleItem[subKey];
                    if(element.link == path) {
                        if(userConnected.hasAccess(element.module)) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                }
            }
        }
    }
}

export const moduleAuthorizedToAccess = (navs, userConnected) => {
    let navigation = [];
    navs.map(moduleItem => {
        // Si la clé 'module' existe
        if(moduleItem.module) {
            // console.log('hello', moduleItem.module, moduleItem.items)
            // Si ce module ce trouve dans la liste d'accès des accès du userconnected
            if(userConnected.acces.hasOwnProperty(moduleItem.module) || moduleItem.module == 'home' || moduleItem.module == 'listStores' || moduleItem.module == 'textMenu') {
                // Est ce que ce module à des sous accès ou sous menu
                if(moduleItem.items) {
                    let listItems = [];
                    // On parcours ses sous menus
                    moduleItem.items.map(item => {
                        // Est ce ques ses sous menus ont des modules
                        if(item.module) {
                            const module = item.module.split('.')[0];
                            const sub = item.module.split('.')[1];
                            // console.log(module);
                            
                            // Est ce que ce sous menu se trouve dans la liste d'accès du userConnected
                            if(userConnected.acces[module].includes(sub)) {
                                // On rajoute ce sous menu dans la liste
                                listItems.push(item);
                            }
                        } else {
                            // console.log(moduleItem.module);
                        }
                    });

                    // On assigne tout les sous menus au quel il a accès dans la liste des items de ce module en cours
                    moduleItem.items = listItems;
                }/*  else if(Object.(navs[moduleItem.module]).length > 0) {
                    console.log(navs[moduleItem.module]);
                } */

                // On assigne la liste des modules dont il a accès dans la navigation
                navigation.push(moduleItem);
            }
        }
    });
    
    return navigation;
}

export const linkAllowedToAccess = (access) => {
    let lists = new Object();
    lists[`home.index`] = true;
    
    for (const key in routesLists) {
        if (Object.hasOwnProperty.call(routesLists, key)) {
            const module = routesLists[key];
            lists[`${key}`] = true;
            for (const elementKey in module) {
                if (Object.hasOwnProperty.call(module, elementKey)) {
                    const element = module[elementKey];
                    element['module'] = `${key}.${elementKey}`;
                    
                    if(access.hasOwnProperty(key)) {
                        if((access[key] && access[key].includes(elementKey))) {
                            lists[`${key}.${elementKey}`] = true;
                        } else {
                            lists[`${key}.${elementKey}`] = false;
                        }
                    } else {
                        lists[`${key}.index`] = true;
                    } 
                }
            }
        }
    }

    return lists;
}

export const isLoggedIn = () => {
    let token = localStorage.getItem('token');

    if(token) {
        return true;
    } else {
        return false;
    }
}

export const logout = async () => {
    const logout = '/manager.logout';
    localStorage.removeItem('token');
    localStorage.removeItem('storeSelected');
    
    await axios.post(logout).then(res => {
        res = res.data;

        if(window.location.pathname !== '/login') {
            window.location.href = route('login.index');
        }
        
    }).catch(err => {
        alertMessage('Impossible de joindre le serveur', 'danger');
    });

    if(window.location.pathname !== '/login') {
        window.location.href = route('login.index');
    }

    /* if(token) {
    } else {
    } */
}

export const isActive = async (data) => {
    let result = null;
    await axios.post(data.url, data).then(res => {
        res = res.data;
        result = res;
    });

    return result;
}

export const random = (min, max) => {
    // Utilisez Math.random() pour obtenir un nombre décimal entre 0 (inclus) et 1 (exclu)
    const nombreDecimal = Math.random();
  
    // Multipliez par la plage pour obtenir un nombre dans la plage souhaitée
    const nombreDansPlage = nombreDecimal * (max - min) + min;
  
    // Utilisez Math.floor() pour arrondir vers le bas et obtenir un nombre entier
    const nombreEntier = Math.floor(nombreDansPlage);
  
    return nombreEntier;
}

export const getColor = (colors = ['primary', 'info', 'warning', 'option', 'pink', 'youtube', 'success', 'tiffany', 'purple', 'orange', 'twitter']) => {
    // Tableau de couleurs
    // let colors = ['primary', 'info', 'warning', 'option', 'pink', 'youtube', 'success'];

    // Tableau des couleurs utilisées (initialisé s'il n'existe pas encore)
    if (!getColor.usedColors) {
        getColor.usedColors = [];
    }

    // Vérifiez si toutes les couleurs ont été utilisées
    if (getColor.usedColors.length === colors.length) {
        // Réinitialisez le tableau des couleurs utilisées
        getColor.usedColors = [];
    }

    // Sélectionnez une couleur aléatoire non utilisée
    let randomIndex;
    do {
        randomIndex = Math.floor(Math.random() * colors.length);
    } while (getColor.usedColors.includes(randomIndex));

    // Marquez la couleur comme utilisée
    getColor.usedColors.push(randomIndex);

    // Retournez la couleur sélectionnée
    return colors[randomIndex];
}

/* export const getColor = (lists = ['primary', 'info', 'warning', 'option', 'pink', 'youtube']) => {
    const colors = lists;
    // data[keys].splice(i, 1);
    // ${colors[random(0, colors.length)]
    var selectedColor = colors[random(0, colors.length)];
    let colorsSelected = localStorage.getItem('colorsSelected');

    // console.log('1', colorsSelected);

    if(colorsSelected == null) {
        colorsSelected = [selectedColor];
        localStorage.setItem('colorsSelected', JSON.stringify(colorsSelected));
    } else {
        colorsSelected = JSON.parse(colorsSelected);

        if(colors.length == colorsSelected.length) {
            localStorage.removeItem('colorsSelected');
            colorsSelected = [selectedColor];
            localStorage.setItem('colorsSelected', JSON.stringify(colorsSelected));
        } else {
            do {
                selectedColor = colors[random(0, colors.length)];
            } while (colorsSelected.includes(selectedColor));

            colorsSelected.push(selectedColor);
            localStorage.setItem('colorsSelected', JSON.stringify(colorsSelected));
        }
    }

    return selectedColor;
} */

export const getBgColor = (colors = ['primary', 'info', 'warning', 'option', 'pink', 'youtube', 'success']) => {
    return `bg-${getColor(colors)}`;
}

export const getOptionsSetting = (type) => {
    for (const key in OPTIONS_SETTING) {
        if (Object.hasOwnProperty.call(OPTIONS_SETTING, key)) {
            const element = OPTIONS_SETTING[key];
            if(element.type == type) {
                return element;
            }
        }
    }

    return false;
}

export const getOptionsSettingAppointmentName = (type) => {
    for (const key in OPTIONS_SETTINGS_APPOINTMENTS) {
        if (Object.hasOwnProperty.call(OPTIONS_SETTINGS_APPOINTMENTS, key)) {
            const element = OPTIONS_SETTINGS_APPOINTMENTS[key];
            if(element.type == type) {
                return element;
            }
        }
    }

    return false;
}

export const loading = (show = true) => {
    if(show) {
        $('.axiosLoading').toggle();
        $('.axioLoader').toggle();
    } else {
        $('.axiosLoading').fadeOut();
        $('.axioLoader').fadeOut();
    }
}

export const dataTable = (tables = null) => {
    setTimeout(() => {
        let element = '';
        if(tables == null) {
            // element = "#dataTable-list";
            if (!$.fn.DataTable.isDataTable("#dataTable-list")) {
                $(document).ready(function () {
                    $("#dataTable-list").DataTable({
                        pageLength: 10,
                        processing: true,
        
                        lengthMenu: [
                        [10, 25, 50, 100, 200, -1],
                        [10, 25, 50, 100, 200, "Tout"],
                        ],
                        
                        language: {
                            url: 'https://cdn.datatables.net/plug-ins/1.11.5/i18n/fr-FR.json',
                        },
                    });
                });
            }
        } else {
            tables.forEach(element => {
                // On supprime si sa existe
                // let table = $("."+element).DataTable();
                // table.destroy();
                // On initialise
                if (!$.fn.DataTable.isDataTable('.'+element)) {
                    $(document).ready(function () {
                        $('.'+element).DataTable({
                            pageLength: 10,
                            processing: true,
            
                            lengthMenu: [
                                [10, 25, 50, 100, 200, -1],
                                [10, 25, 50, 100, 200, "Tout"],
                            ],
                            
                            language: {
                                url: 'https://cdn.datatables.net/plug-ins/1.11.5/i18n/fr-FR.json',
                            },
                        });
                    });
                }
            });
        }
    }, 100);
}

export const reloadDataTable = () => {
    let table = $("#dataTable-list").DataTable();
    table.destroy();
    dataTable();
}

export const destroyDataTable = (tables = null) => {
    let element = '';
    if(tables == null) {
        let table = $("#dataTable-list").DataTable();
        if(table) {
            table.destroy();
        }
    } else {
        tables.forEach(element => {
            let table = $("."+element).DataTable();
            table.destroy();
            return;
        });
    }
}

export const deleteItem = (e, options, setOptionForDeletingItem) => {
    e.preventDefault();
    setOptionForDeletingItem(options);
}

export const removeItemInDataList = (dataList, item) => {
    const lists = dataList;
    Object.entries(lists).filter(([key, element]) => {
        if(element.id == item) {
            lists.splice(key, 1);
            // Reflect.deleteProperty(lists, key);
        }
    });

    return Object.values(lists);
}

export const refreshDataList = (data, deleted) => {
    if(typeof data == 'object') {
        for (let i = 0; i < Object.keys(data).length; i++) {
            const element = data[i];
            if(element.id == deleted) {
                // delete data[i];
                Reflect.deleteProperty(data, i);
                break;
            }
        }

        return Object.values(data);

        /* Object.keys(data).map((keys, i) => {
            if(Array.isArray(data[keys])) {
                data[keys].map((element, i) => {
                    if(element.id == deleted) {
                        data[keys].splice(i, 1);
                        if(data[keys].length == 0) {
                            Reflect.deleteProperty(data, keys);
                            // delete data[keys];
                        }
                        return;
                    }
                });
            }
        }); */
    } else {
        data.map((element, i) => {
            if(element.id == deleted) {
                data.splice(i, 1);
                return;
            }
        });
    }
    
    return data;
}

export const refreshDataList1 = (data, deleted) => {
    data.map((element, i) => {
        if(element.id == deleted) {
            data.splice(i, 1);
            $('#item-'+ (i+1)).remove();
            console.log($('#item-'+ (i+1)).html(), i, '#item-'+ (i+1));
            return;
        }
    });
    
    return data;
}

export const handleEnabledOrDisabled = (e, url, id) => {
    axios.post(url, { id, active: e.target.checked }).then(res => {
        res = res.data;
        if(res.validate) {
            alertMessage(res.message, 'success');
        } else {
            alertMessage(res.message, 'danger');
        }
    });
}