import { cilArrowRight } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CNavItem } from '@coreui/react';
import React from 'react';

// export const baseURL = process.env.REACT_APP_BASE_URL;
const protocol = window.location.protocol;
const hostname = window.location.hostname;
// const host = protocol + '//' + hostname + '/serveurs/cliniqueaprosafe'
const host = '//cliniqueaprosafe.com/serveurs/cliniqueaprosafe'
// alert(host);
export const baseURL = host;
export const TinyAPIKey = "a4ta5t5fqxkd01ddol3apqnhl0ii287iv7gj7mq0ohm4dpzl";
export const PreferedProductName = "Prestations";

// Accueil
const Dashboard = React.lazy(() => import('src/views/dashboard/Dashboard'));

// Login
const Login = React.lazy(() => import('src/views/pages/login/Login'));

// Suppréssion d'un item
const DeleteElement = React.lazy(() => import('src/views/layouts/DeleteElement'));

// Boutiques
const Stores = React.lazy(() => import('src/views/stores/Stores'));
const AddStore = React.lazy(() => import('src/views/stores/Create'));
const EditStore = React.lazy(() => import('src/views/stores/Edit'));
const ShowStore = React.lazy(() => import('src/views/stores/Show'));

// Image d'accueil
const Carousels = React.lazy(() => import('src/views/carouselImages/Carousels'));
const AddCarouselImage = React.lazy(() => import('src/views/carouselImages/Create'));
const EditCarouselImage = React.lazy(() => import('src/views/carouselImages/Edit'));
const ShowCarouselImage = React.lazy(() => import('src/views/carouselImages/Show'));

// spécialités
const Specialities = React.lazy(() => import('src/views/specialities/Specialities'));
const AddSpeciality = React.lazy(() => import('src/views/specialities/Create'));
const EditSpeciality = React.lazy(() => import('src/views/specialities/Edit'));
const ShowSpeciality = React.lazy(() => import('src/views/specialities/Show'));

// Services
const Services = React.lazy(() => import('src/views/services/Services'));
const AddService = React.lazy(() => import('src/views/services/Create'));
const EditService = React.lazy(() => import('src/views/services/Edit'));
const ShowService = React.lazy(() => import('src/views/services/Show'));

// Partenaires
const Partners = React.lazy(() => import('src/views/partners/Partners'));
const AddPartner = React.lazy(() => import('src/views/partners/Create'));
const EditPartner = React.lazy(() => import('src/views/partners/Edit'));
const ShowPartner = React.lazy(() => import('src/views/partners/Show'));

// Médecins
const Doctors = React.lazy(() => import('src/views/doctors/Doctors'));
const AddDoctor = React.lazy(() => import('src/views/doctors/Create'));
const EditDoctor = React.lazy(() => import('src/views/doctors/Edit'));
const ShowDoctor = React.lazy(() => import('src/views/doctors/Show'));

// Rendez-vous
const Appointments = React.lazy(() => import('src/views/appointments/Appointments'));
const AddAppointment = React.lazy(() => import('src/views/appointments/Create'));
const EditAppointment = React.lazy(() => import('src/views/appointments/Edit'));
const ShowAppointment = React.lazy(() => import('src/views/appointments/Show'));

// Employees
const Employees = React.lazy(() => import('src/views/employees/Employees'));
const AddEmployee = React.lazy(() => import('src/views/employees/Create'));
const EditEmployee = React.lazy(() => import('src/views/employees/Edit'));
const ShowEmployee = React.lazy(() => import('src/views/employees/Show'));

// Paramètres RDV
const AppointmentSettings = React.lazy(() => import('src/views/appointment_settings/AppointmentSettings'));
const EditAppointmentSettings= React.lazy(() => import('src/views/appointment_settings/Edit'));
const AddAppointmentSettings= React.lazy(() => import('src/views/appointment_settings/Create'));
const DeleteAppointmentSettings= React.lazy(() => import('src/views/appointment_settings/Delete'));

// Paramètres
const Settings = React.lazy(() => import('src/views/settings/Settings'));
const EditSetting = React.lazy(() => import('src/views/settings/Edit'));
const AddSetting = React.lazy(() => import('src/views/settings/Create'));
const DeleteSetting = React.lazy(() => import('src/views/settings/Delete'));





// Ventes
const Prestations = React.lazy(() => import('src/views/prestations/Prestations'));
const AddPrestation = React.lazy(() => import('src/views/prestations/Create'));
const EditPrestation = React.lazy(() => import('src/views/prestations/Edit'));
const ShowPrestation = React.lazy(() => import('src/views/prestations/Show'));

// Clients
const Customers = React.lazy(() => import('src/views/customers/Customers'));
const AddCustomer = React.lazy(() => import('src/views/customers/Create'));
const EditCustomer = React.lazy(() => import('src/views/customers/Edit'));
const ShowCustomer = React.lazy(() => import('src/views/customers/Show'));

// Plannings
const Schedules = React.lazy(() => import('src/views/schedules/Schedules'));
const AddSchedule = React.lazy(() => import('src/views/schedules/Create'));
const EditSchedule = React.lazy(() => import('src/views/schedules/Edit'));
const ShowSchedule = React.lazy(() => import('src/views/schedules/Show'));

// Comptabilité
const Accounting = React.lazy(() => import('src/views/comptability/Accounting'));
const ShowAccounting = React.lazy(() => import('src/views/comptability/Show'));
// const Accounting = React.lazy(() => import('src/views/settings/Settings'));
// const AddAccounting = React.lazy(() => import('src/views/accounting/Create'));

const Checkouts = React.lazy(() => import('src/views/checkout/Checkouts'));
const AddCheckout = React.lazy(() => import('src/views/checkout/Create'));
const ShowCheckout = React.lazy(() => import('src/views/checkout/Show'));

// Comptes
const Accounts = React.lazy(() => import('src/views/account/Accounts'));
const AddAccount = React.lazy(() => import('src/views/account/Create'));
const EditAccount = React.lazy(() => import('src/views/account/Edit'));
const ShowAccount = React.lazy(() => import('src/views/account/Show'));

const routes = {
    // Page d'accueil
    home: {
        // name: 'Accueil',
        index: {
            name: 'Accueil',
            link: '/',
            element: Dashboard
        },
    },
    
    // Connexion
    login: {
        index: {
            name: 'Connexion',
            link: '/login',
            element: Login
        },
    },
    
    // Image d'accueil
    carouselImages: {
        index: {
            name: "Image d'accueil",
            link: '/carousels',
            element: Carousels
        },
        add: {
            name: 'Ajouter',
            link: '/carousel/add',
            element: AddCarouselImage
        },
        edit: {
            name: 'Modifier',
            link: '/carousel/edit/:id',
            element: EditCarouselImage
        },
        show: {
            name: 'Afficher',
            link: '/carousel/show/:id',
            element: ShowCarouselImage
        },
        delete: {
            name: 'Suppréssion',
            link: '/carousel/delete/:id',
            element: DeleteElement
        },
    },
    
    // Spécialités
    specialities: {
        index: {
            name: "Spécialités",
            link: '/speciality',
            element: Specialities
        },
        add: {
            name: 'Ajouter',
            link: '/speciality/add',
            element: AddSpeciality
        },
        edit: {
            name: 'Modifier',
            link: '/speciality/edit/:id',
            element: EditSpeciality
        },
        show: {
            name: 'Afficher',
            link: '/speciality/show/:id',
            element: ShowSpeciality
        },
        delete: {
            name: 'Suppréssion',
            link: '/speciality/delete/:id',
            element: DeleteElement
        },
    },

    // Services
    services: {
        index: {
            name: "Services",
            link: '/services',
            element: Services
        },
        add: {
            name: 'Ajouter',
            link: '/service/add',
            element: AddService
        },
        edit: {
            name: 'Modifier',
            link: '/service/edit/:id',
            element: EditService
        },
        show: {
            name: 'Affichage',
            link: '/service/show/:id',
            element: ShowService
        },
        delete: {
            name: 'Suppréssion',
            link: '/service/delete/:id',
            element: DeleteElement
        },
    },

    // Partenaires
    partners: {
        index: {
            name: "Partenaires",
            link: '/partners',
            element: Partners
        },
        add: {
            name: 'Ajouter',
            link: '/partner/add',
            element: AddPartner
        },
        edit: {
            name: 'Modifier',
            link: '/partner/edit/:id',
            element: EditPartner
        },
        show: {
            name: 'Afficher',
            link: '/partner/show/:id',
            element: ShowPartner
        },
        delete: {
            name: 'Suppréssion',
            link: '/partner/delete/:id',
            element: DeleteElement
        },
    },
    
    // Doctors
    doctors: {
        index: {
            name: 'Médecins',
            link: '/doctors',
            element: Doctors
        },
        add: {
            name: 'Ajouter',
            link: '/doctor/add',
            element: AddDoctor
        },
        edit: {
            name: 'Modifier',
            link: '/doctor/edit/:id',
            element: EditDoctor
        },
        show: {
            name: 'Affichage',
            link: '/doctor/show/:id',
            element: ShowDoctor
        },
        delete: {
            name: 'Suppréssion',
            link: '/doctor/delete/:id',
            element: DeleteElement
        },
    },
    
    // Rendez-vous
    appointments: {
        index: {
            name: 'Rendez-vous',
            link: '/appointments',
            element: Appointments
        },
        add: {
            name: 'Ajouter',
            link: '/appointment/add',
            element: AddAppointment
        },
        edit: {
            name: 'Modifier',
            link: '/appointment/edit/:id',
            element: EditAppointment
        },
        show: {
            name: 'Affichage',
            link: '/appointment/show/:id',
            element: ShowAppointment
        },
        delete: {
            name: 'Suppréssion',
            link: '/appointment/delete/:id',
            element: DeleteElement
        },
    },

    // Employées
    employees: {
        index: {
            name: 'Employées',
            link: '/employees',
            element: Employees
        },
        add: {
            name: 'Ajouter',
            link: '/employee/add',
            element: AddEmployee
        },
        edit: {
            name: 'Modifier',
            link: '/employee/edit/:id',
            element: EditEmployee
        },
        show: {
            name: 'Afficher',
            link: '/employee/show/:id',
            element: ShowEmployee
        },
        /* delete: {
            name: 'Afficher',
            link: '/employees/delete/:id',
            element: DeleteElement
        }, */
    },

    // Configurations RDV
    appointment_settings: {
        index: {
            name: 'Configurations rendez-vous',
            link: '/appointment_setting/:type',
            element: AppointmentSettings
        },
        add: {
            name: 'Ajouter',
            link: '/appointment_setting/:type/add',
            element: AddAppointmentSettings
        },
        edit: {
            name: 'Modifier',
            link: '/appointment_setting/:type/edit/:id',
            element: EditAppointmentSettings
        },
        delete: {
            name: 'Supprimer',
            link: '/appointment_setting/:type/delete/:id',
            element: DeleteSetting
        },
    },
    
    // Paramètres
    settings: {
        index: {
            name: 'Paramètres',
            link: '/setting/:type',
            element: Settings
        },
        add: {
            name: 'Ajouter',
            link: '/setting/:type/add',
            element: AddSetting
        },
        edit: {
            name: 'Modifier',
            link: '/setting/:type/edit/:id',
            element: EditSetting
        },
        delete: {
            name: 'Supprimer',
            link: '/setting/:type/delete/:id',
            element: DeleteSetting
        },
    },



    // Ventes
    prestations: {
        index: {
            name: "Ventes",
            link: '/prestations',
            element: Prestations
        },
        add: {
            name: 'Ajouter',
            link: '/prestation/add',
            element: AddPrestation
        },
        edit: {
            name: 'Modifier',
            link: '/prestation/edit/:id',
            element: EditPrestation
        },
        show: {
            name: 'Affichage',
            link: '/prestation/show/:id',
            element: ShowPrestation
        },
        delete: {
            name: 'Suppréssion',
            link: '/prestation/delete/:id',
            element: DeleteElement
        },
    },
    
    // Customers
    customers: {
        index: {
            name: 'Clients',
            link: '/customers',
            element: Customers
        },
        add: {
            name: 'Ajouter',
            link: '/customer/add',
            element: AddCustomer
        },
        edit: {
            name: 'Modifier',
            link: '/customer/edit/:id',
            element: EditCustomer
        },
        show: {
            name: 'Affichage',
            link: '/customer/show/:id',
            element: ShowCustomer
        },
        delete: {
            name: 'Suppréssion',
            link: '/customer/delete/:id',
            element: DeleteElement
        },
    },
    
    // Plannings
    schedules: {
        index: {
            name: 'Plannings',
            link: '/schedules',
            element: Schedules
        },
        add: {
            name: 'Ajouter',
            link: '/schedule/add',
            element: AddSchedule
        },
        edit: {
            name: 'Modifier',
            link: '/schedule/edit/:id',
            element: EditSchedule
        },
        show: {
            name: 'Afficher',
            link: '/schedule/show/:id',
            element: ShowSchedule
        },
        delete: {
            name: 'Supprimer',
            link: '/schedule/delete/:id',
            element: DeleteElement
        },
    },

    // Comptabilité
    accounting: {
        index: {
            name: 'Comptabilité',
            link: '/accounting/:type',
            element: Accounting
        },
        show: {
            name: 'Afficher',
            link: '/accounting/:type/show/:id',
            element: ShowAccounting
        },
        /* add: {
            name: 'Ajouter',
            link: '/accounting/:type/add',
            element: AddSetting
        },
        edit: {
            name: 'Modifier',
            link: '/accounting/:type/edit/:id',
            element: EditSetting
        }, */
        delete: {
            name: 'Supprimer',
            link: '/accounting/:type/delete/:id',
            element: DeleteSetting
        },
    },

    // Caisse
    checkouts: {
        index: {
            name: 'Comptabilité',
            link: '/checkouts',
            element: Checkouts
        },
        income: {
            name: 'Nouvelle entrée',
            link: '/checkout/:type/create',
            element: AddCheckout
        },
        expense: {
            name: 'Nouvelle sortie',
            link: '/checkout/:type/create',
            element: AddCheckout
        },
        show: {
            name: 'Affichage',
            link: '/checkout/show/:type/:id',
            element: ShowCheckout
        },
        delete: {
            name: 'Suppréssion',
            link: '/checkout/delete/:id',
            element: DeleteElement
        },
    },
    
    // Comptes
    accounts: {
        index: {
            name: 'Comptes',
            link: '/accounts',
            element: Accounts
        },
        add: {
            name: 'Ajouter',
            link: '/account/add',
            element: AddAccount
        },
        edit: {
            name: 'Modifier',
            link: '/account/edit/:id',
            element: EditAccount
        },
        show: {
            name: 'Affichage',
            link: '/account/show/:id',
            element: ShowAccount
        },
        delete: {
            name: 'Supprimer',
            link: '/account/delete/:id',
            element: DeleteElement
        },
    },
}

export const routesLists = routes;

export const routesName = (name, params) => {
    let pages = name.split('.');
    let link = '/';
    let page = pages[0];
    
    if(pages.length == 1) {
        let url = [];
        
        link = routes[page];
        
    } else if(pages.length == 2) {
        let second = pages[1];

        if(second == 'name') {
            link = routes[page][second];
        } else {
            link = routes[page][second]['link'];
        }
    } else if(pages.length == 3) {
        let second = pages[1];
        let third = pages[2];

        link = routes[page][second][third];
    }

    if(params != null) {
        if(params instanceof Object) {
            for (const key in params) {
                if (Object.hasOwnProperty.call(params, key)) {
                    const value = params[key];
                    let regex = `/[:${key}]/i`
                    link = link.replace(`:${key}`, value);
                }
            }
        }
    }

    setLink(name, link);

    return link;
}

export var links = [];
export const setLink = (name, link) => {
    links[link] = name;
}

export const APP_MODULES = {
    // stores: ['index', 'add', 'edit', 'show', 'delete'],
    // schedules: ['index', 'add', 'edit', 'show', 'delete'],
    // prestations: ['index', 'add', 'edit', 'show', 'delete'],
    carouselImages: ['index', 'add', 'edit', 'show', 'delete'],
    specialities: ['index', 'add', 'edit', 'show', 'delete'],
    services: ['index', 'add', 'edit', 'show', 'delete'],
    partners: ['index', 'add', 'edit', 'show', 'delete'],
    doctors: ['index', 'add', 'edit', 'show', 'delete'],
    employees: ['index', 'add', 'edit', 'show', 'delete'],
    settings: ['index', 'add', 'edit', 'delete', 'website_infos', 'type_employee'],
    appointments: ['index', 'add', 'edit', 'show', 'delete'],
    appointment_settings: ['index', 'add', 'edit', 'show', 'delete', 'doctor_availability', 'reason_appointment', 'consultation_interval'],
    
    // customers: ['index', 'add', 'edit', 'show', 'delete'],
    // accounting: ['index', 'add', 'edit', 'show', 'delete'],
    // accounts: ['index', 'add', 'edit', 'show', 'delete'],
};

export const TEXT_APP_MODULES = {
    // stores: "Boutiques",
    // schedules: "Plannings employés",
    // prestations: "Prestations",
    carouselImages: "Images d'accueil",
    specialities: "Spécialités",
    services: "Services",
    partners: "Partenaires",
    doctors: "Médecins",
    employees: "Employé(e)s",
    appointments: "Rendez-vous",
    appointment_settings: "Config. rendez-vous",
    settings: "Paramètres",
    index: "Listes d'élements",
    add: "Ajouter",
    edit: "Modifier",
    show: "Afficher l'élément",
    delete: "Suppr. l'élément",
    website_infos: "Infos du site",
    type_employee: "Type d'employé",
    doctor_availability: "Dispo. du médecin",
    reason_appointment: "Motif de rendez-vous",
    consultation_interval: "Intervalle consultation",
    
    // customers: "Clients",
    // accounting: "Comptabilité",
    // accounts: "Compte de paiement",
};

/* export const SCHEDULES_TYPE = [
    { value: 1, name: "Affectation" },
    { value: 2, name: "Transfert" },
    { value: 3, name: "Remplacer" },
]; */

export const SCHEDULES_TYPE = {
    1: "Affectation",
    2: "Transfert",
    3: "Remplacer",
}

export const TYPE_ACCOUNTING = [
    {
        component: CNavItem,
        name: 'Nouvelle entrée',
        to: routesName('accounting.index', {type: 'income'}),
        type: 'income',
        icon: <CIcon icon={cilArrowRight} customClassName="nav-icon sub-nav-icon" />,
        module: 'accounting.index',
    },
    {
        component: CNavItem,
        name: 'Nouvelle sortie',
        to: routesName('accounting.index', {type: 'expense'}),
        type: 'expense',
        icon: <CIcon icon={cilArrowRight} customClassName="nav-icon sub-nav-icon" />,
        module: 'accounting.index',
    },
    {
        component: CNavItem,
        name: 'Historiques',
        to: routesName('accounting.index', {type: 'history'}),
        type: 'history',
        icon: <CIcon icon={cilArrowRight} customClassName="nav-icon sub-nav-icon" />,
        module: 'accounting.index',
    }
];

export const OPTIONS_SETTINGS_APPOINTMENTS = [
    {
        component: CNavItem,
        name: "Dispo. du médecin",
        to: routesName('appointment_settings.index', {type: 'doctor_availability'}),
        type: 'doctor_availability',
        icon: <CIcon icon={cilArrowRight} customClassName="nav-icon sub-nav-icon" />,
        module: 'appointment_settings.doctor_availability',
    },
    {
        component: CNavItem,
        name: "Motif de rendez-vous",
        to: routesName('appointment_settings.index', {type: 'reason_appointment'}),
        type: 'reason_appointment',
        icon: <CIcon icon={cilArrowRight} customClassName="nav-icon sub-nav-icon" />,
        module: 'appointment_settings.reason_appointment',
    },
    /* {
        component: CNavItem,
        name: "Intervalle consultation",
        to: routesName('appointment_settings.index', {type: 'consultation_interval'}),
        type: 'consultation_interval',
        icon: <CIcon icon={cilArrowRight} customClassName="nav-icon sub-nav-icon" />,
        module: 'appointment_settings.consultation_interval',
    }, */
];

export const OPTIONS_SETTING = [
    {
        component: CNavItem,
        name: "Infos du site",
        to: routesName('settings.index', {type: 'infos'}),
        type: 'infos',
        icon: <CIcon icon={cilArrowRight} customClassName="nav-icon sub-nav-icon" />,
        module: 'settings.website_infos',
    },
    {
        component: CNavItem,
        name: 'Type d\'employé',
        to: routesName('settings.index', {type: 'role'}),
        type: 'role',
        icon: <CIcon icon={cilArrowRight} customClassName="nav-icon sub-nav-icon" />,
        module: 'settings.type_employee',
    },
    /* {
        component: CNavItem,
        name: 'Type pièce d\'identité',
        to: routesName('settings.index', {type: 'type_identity'}),
        type: 'type_identity',
        icon: <CIcon icon={cilArrowRight} customClassName="nav-icon sub-nav-icon" />,
        module: 'settings.index',
    },
    {
        component: CNavItem,
        name: 'Période de paiement',
        to: routesName('settings.index', {type: 'payment_period'}),
        type: 'payment_period',
        icon: <CIcon icon={cilArrowRight} customClassName="nav-icon sub-nav-icon" />,
        module: 'settings.index',
    },
    {
        component: CNavItem,
        name: 'Méthode de paiement',
        to: routesName('settings.index', {type: 'payment_method'}),
        type: 'payment_method',
        icon: <CIcon icon={cilArrowRight} customClassName="nav-icon sub-nav-icon" />,
        module: 'settings.index',
    },
    {
        component: CNavItem,
        name: 'Type de sexe',
        to: routesName('settings.index', {type: 'sex'}),
        type: 'sex',
        icon: <CIcon icon={cilArrowRight} customClassName="nav-icon sub-nav-icon" />,
        module: 'settings.index',
    }, */
    /* {
        component: CNavItem,
        name: "Catégorie d'article",
        to: routesName('settings.index', {type: 'article_category'}),
        type: 'article_category',
        icon: <CIcon icon={cilArrowRight} customClassName="nav-icon sub-nav-icon" />,
        module: 'settings.index',
    },
    {
        component: CNavItem,
        name: 'Catégorie de boutique',
        to: routesName('settings.index', {type: 'store_category'}),
        type: 'store_category',
        icon: <CIcon icon={cilArrowRight} customClassName="nav-icon sub-nav-icon" />,
        module: 'settings.index',
    }, */
];

export const DAY_WEEK = {
    1: 'Lundi',
    2: 'Mardi',
    3: 'Mercredi',
    4: 'Jeudi',
    5: 'Vendredi',
    6: 'Samedi',
    7: 'Dimanche'
};

export const MONTHS = {
    1: 'Janvier',
    2: 'Février',
    3: 'Mars',
    4: 'Avril',
    5: 'Mai',
    6: 'Juin',
    7: 'Juillet',
    8: 'Août',
    9: 'Septembre',
    10: 'Octobre',
    11: 'Novembre',
    12: 'Décembre'
};

let years = new Object();
let currentYear = (new Date()).getFullYear()

for (let i=2024; i <= currentYear; i++) { 
    years[i] = i;
}

export const YEARS = years;



/* export const OPTIONS_SETTING = {
    site_type: {
        icon: 'lni lni-apartment',
        text: 'Type de site'
    },
    penality: {
        icon: 'fa fa-arrow-right',
        text: 'Type de sanction'
    },
    bonus: {
        icon: 'fa fa-arrow-right',
        text: 'Type d\'encouragement'
    },
    payment_period: {
        icon: 'lni lni-calendar',
        text: 'Période de paiement'
    },
    payment_method: {
        icon: 'lni lni-credit-cards',
        text: 'Méthode de paiement'
    },
    type_identity: {
        icon: 'far fa-id-card',
        text: 'Type pièce d\'identité'
    },
    role: {
        icon: 'fa fa-user-cog',
        text: 'Type d\'employé'
    },
    sex: {
        icon: 'fa fa-user-cog',
        text: 'Type de sexe'
    },
}; */