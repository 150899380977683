import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons';
import axios from 'src/contexts/AxiosInstance';
import { useStateContext } from 'src/contexts/ContextProvider';
import { alertMessage } from 'src/assets/js/functions';
import { routesName as route } from 'src/constants/constants';

const ResetPassword = () => {
  const {token} = useParams();
	const [formData, setFormData] = useState({
		token: token,
		password: '',
		copy_password: '',
	});

    useEffect(() => {
      const url = baseURL + '/manager.getInfosForResetPassword'
      const data = { token }

      axios.post(url, data).then(res => {
        res = res.data;
        if(res.validate == true) {
          setFormData({
            ...formData,
            ['csrf']: res.message,
          });
        } else {
          alertMessage(res.message, 'danger');
        }
      });
    }, []);

  // Changement des values des inputs
  const handleChange = (e) => {
    const value = e.target.value;
    setFormData({
      ...formData,
      [e.target.name]: value
    });
  }

  const { baseURL } = useStateContext();
	const sendData = baseURL + '/manager.resetPassword';
  
	// Soummission du formulaire de connexion
	const handleSubmit = (e) => {
		e.preventDefault();
		
		axios.post(sendData, formData).then(res => {
			res = res.data;
			if(res.validate == true) {
        alertMessage(res.message, 'success');
        
        setTimeout(() => {
          window.location.href = route('login.index');
        }, 2000);
			} else {
        if(typeof res.message == 'string') {
            alertMessage(res.message, 'danger');
        } else {
            let errors = res.message;
            let li = new Array();
            for (const key in errors) {
                if (Object.hasOwnProperty.call(errors, key)) {
                    const listErrors = errors[key];
                    if(listErrors) {
                        listErrors.forEach((error, index) => {
                            // modification de 'index' pour ne pas avoir les mêmes valeurs
                            index = ((index + 1) * Math.random());
                            li.push(<li key={index}>{error}</li>);
                        });
                    }
                }
            }
            
            alertMessage(<ul style={{ marginLeft: '15px', marginBottom: 0, paddingLeft: 0 }}>{li}</ul>, 'danger');

        }
			}
		});
	}
  
  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol lg={8} xs={12}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm method='post'>
                    <h1>Réinitialiser votre mot de passe</h1>
                    {/* <p className="text-medium-emphasis">Ajouter le nouveau mot de passe</p> */}

                    <label htmlFor="">Nouveau mot de passe</label>
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type="password"
                        name="password"
                        placeholder="Nouveau mot de passe"
                        autoComplete="current-password"
                        value={formData.password}
                        onChange={handleChange}
                      />
                    </CInputGroup>

                    <label htmlFor="">Retaper le nouveau mot de passe</label>
                    <CInputGroup className="mb-4">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type="password"
                        name="copy_password"
                        placeholder="confirmer le nouveau mot de passe"
                        autoComplete="current-copy_password"
                        value={formData.copy_password}
                        onChange={handleChange}
                      />
                    </CInputGroup>
                    <CRow>
                      <CCol xs={7}>
                        <button className='btn btn-primary px-4' onClick={handleSubmit}>
                          Valider
                        </button>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default ResetPassword
